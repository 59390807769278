var vovLink = document.querySelector('.switcher__button.vov');
var vovImageBlock = document.querySelector('.navigation__image-link.vov');

var nrovLink = document.querySelector('.switcher__button.nrov');
var nrovImageBlock = document.querySelector('.navigation__image-link.nrov');

var switcherText = document.querySelector('.switcher__text');



vovLink.addEventListener('mouseenter', function(e) {
  nrovImageBlock.classList.add('disabled');
  vovImageBlock.classList.remove('disabled');
});

nrovLink.addEventListener('mouseenter', function(e) {
  vovImageBlock.classList.add('disabled');
  nrovImageBlock.classList.remove('disabled');
});


function disableVovImageLink() {
  vovImageBlock.classList.add('disabled');
}

function disableNrovImageLink() {
  nrovImageBlock.classList.add('disabled');
}

var windowWidth = window.innerWidth;

if (windowWidth > 1024) {
  switcherText.addEventListener('mouseenter', function() {
    vovImageBlock.classList.add('disabled');
    nrovImageBlock.classList.add('disabled');
  });
}


var finalSaleElem = document.querySelector('.percents-eighty ');
function removeHiddenCss() {
  finalSaleElem.classList.remove('hidden')
}
setTimeout(removeHiddenCss, 6100)
